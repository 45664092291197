/*
 * @Author: ChenYaJin
 * @Date: 2023-09-07 10:00:08
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-09-26 17:37:00
 * @Description: 活动资讯
 */
import { http } from '~/utils/fetch/index'
import type { BasicResponse, IOption, Page, TableData } from '~/models/common'
import type {
  IInfoLevel,
  IInfo,
  IActivity,
  IActivityScore,
  IScoreDetail,
  ICommentDetail,
  ExcellenceActivity,
} from '~/models/activity'
import type { EnterpriseCase } from '~/models/enterprise'

export type IActivityOptions = Page & {
  status?: string
  name?: string
  type?: string
}

export type IInfoOptions = Page & {
  classId1?: string
  classId2?: string
  classId3?: string
  carousel?: number
  pushTimeAtSort?: string
  name?: string
}

export interface IRecords {
  id: string
  projectName: string
  auditStatus: string
  reject: string
}

export interface IDetailParams {
  activityId: string
  enterpriseId: string
  communityId: string
}

/**
 * 资讯分类
 * @returns
 */
export function getInfoLevel() {
  return http.request<BasicResponse<IInfoLevel[]>>({
    url: '/infoClass/open',
    method: 'get',
  })
}
/**
 * 资讯列表
 * http://yapi.wisdomcity.com.cn/project/136/interface/api/22523
 * @returns
 */
export function getInfoList(data: IInfoOptions) {
  return http.request<BasicResponse<TableData<IInfo>>>({
    url: '/info/open',
    method: 'get',
    params: data,
  })
}

/**
 * 资讯详情
 * @returns
 */
export function getInfoDetail(id: string) {
  return http.request<BasicResponse<IInfo>>({
    url: `/info/open/${id}`,
    method: 'get',
  })
}

/**
 * 关于协会
 * @returns
 */
export function getAboutOpen() {
  return http.request<BasicResponse<IInfo>>({
    url: `/singlePage/introductionOfTheAssociation/open`,
    method: 'get',
  })
}

/**
 * 关于协会-企业展示
 * @returns
 */
export function getEnterOpen() {
  return http.request<BasicResponse<EnterpriseCase>>({
    url: `/singlePage/enterpriseShowcase/open`,
    method: 'get',
  })
}

/**
 * 关于协会 - 其他页面
 * @param type 类别（联系我们：contactUs，协会领导：associationLeadership，主要职责：primaryResponsibilities）
 * @returns 列表数据
 */
export function getAboutOtherOpen(type: string) {
  return http.request<BasicResponse<IInfo>>({
    url: `/singlePage/standardPage/open`,
    method: 'get',
    params: {
      type,
    },
  })
}

/**
 * admin: 进行中的活动
 * @returns
 */
export function getActivityList(data: IActivityOptions) {
  return http.request<BasicResponse<TableData<IActivity>>>({
    url: '/activity',
    method: 'get',
    params: data,
  })
}

/**
 * admin: 活动类型枚举
 * @returns
 */
export function getActivityTypeEnum() {
  return http.request<BasicResponse<IOption[]>>({
    url: '/activity/typeEnum',
    method: 'get',
  })
}

/**
 * admin: 活动状态枚举
 * @returns
 */
export function getActivityStatusEnum() {
  return http.request<BasicResponse<IOption[]>>({
    url: '/activity/statusEnum',
    method: 'get',
  })
}

/**
 * admin: 根据活动类型查看可以参与活动的参与方
 * @returns
 */
export function getParticipantsByType(data: { activityId: string }) {
  return http.request<BasicResponse<IOption[]>>({
    url: '/activity/ByTypeFindBusiness',
    method: 'get',
    params: data,
  })
}

/**
 * admin: 参与活动
 * @returns
 */
export function participateActivity(data: { businessIds: string[]; activityId: string }) {
  return http.request<BasicResponse<number>>({
    url: '/activity/postParticipant',
    method: 'post',
    data: data,
  })
}

/**
 * admin: 参与市优活动
 * @returns
 */
export function participateExcellenceActivity(data: any) {
  return http.request<BasicResponse<number>>({
    url: '/activity/postMunicipalExcellenceParticipant',
    method: 'post',
    data: data,
  })
}

/**
 * admin: 重新提交参与市优活动数据
 * @returns
 */
export function reParticipateExcellenceActivity(id: string, data: any) {
  return http.request<BasicResponse<number>>({
    url: `/activity/updateMunicipalExcellenceParticipant/${id}`,
    method: 'put',
    data: data,
  })
}

/**
 * admin: 获取参与市优活动记录
 * @returns
 */
export function getParticipateActivityRecords(id: string) {
  return http.request<BasicResponse<IRecords[]>>({
    url: `activity/municipalExcellenceParticipant/${id}`,
    method: 'get',
  })
}

/**
 * admin: 获取保存的审核备案表数据
 * @returns
 */
export function getParticipantDetail(params: IDetailParams) {
  return http.request<BasicResponse<ExcellenceActivity>>({
    url: `activity/municipalExcellenceParticipantDetail`,
    method: 'get',
    params,
  })
}

/**
 * admin: 根据活动类型和参与方查看活动评分列表
 * @returns
 */
export function getActivityScoreList(data: { enterpriseId: string; businessId: string }) {
  const params = {
    ...data,
    ...{
      isPage: false,
    },
  }
  return http.request<BasicResponse<{ list: IActivityScore[] }>>({
    url: '/activity/participant',
    method: 'get',
    params: params,
  })
}

/**
 * admin: 按活动参与方查看评分详情
 * @returns
 */
export function getActivityScoreDetail(data: { participantId: string }) {
  return http.request<BasicResponse<IScoreDetail>>({
    url: `/activity/standardAverage`,
    method: 'get',
    params: data,
  })
}

/**
 * http://yapi.wisdomcity.com.cn/project/128/interface/api/23488
 * admin: 活动评分评语详情
 * @returns
 */
export function getActivityCommentDetail(data: string) {
  return http.request<BasicResponse<ICommentDetail>>({
    url: `/activity/scoringComment/${data}`,
    method: 'get',
  })
}
